import React, { useState } from 'react';
import { useSetAtom } from 'jotai';

import sessionAtom from '../../jotai/atoms/session';

const UserForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const setSession = useSetAtom(sessionAtom);

  const handleNameChange = (e) => {
    setErrorMessage('');
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setErrorMessage('');
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === '') {
      setErrorMessage('Name cannot be empty');
    } else if (email === '') {
      setErrorMessage('Email cannot be empty');
    } else {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        setErrorMessage('');
        setSession((session) => ({
          ...session,
          user: {
            name,
            email,
          },
        }));
      } else {
        setErrorMessage('Please provide a valid email');
      }
    }
  };

  return (
    <div className="user-form-container">
      <form className="user-form" onSubmit={handleSubmit}>
        <div className="user-form__group">
          <input
            type="text"
            placeholder="Full Name"
            defaultValue={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="user-form__group">
          <input
            type="text"
            placeholder="Email"
            defaultValue={email}
            onChange={handleEmailChange}
          />
        </div>
        <div className="user-form__group">
          <button type="submit" className="btn--primary user-form__submit">
            CHECK IN
          </button>
        </div>
        <div className="user-form__error">
          <label>{errorMessage}</label>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
