import { atom } from 'jotai';

const sessionAtom = atom({
  user: null,
  quizId: null,
  duration: null,
  questions: [],
  submitted: false,
});

export default sessionAtom;
