function paramsToObject(urlSearchParams) {
  const result = {};
  for (const [key, value] of urlSearchParams.entries()) {
    result[key] = value;
  }
  return result;
}

// A custom hook that builds on useLocation to parse the query string for you.
function getURLParams() {
  const url = window.location.href.toString();
  const separatorChar = url.indexOf('#') >= 0 ? '#' : '?';
  if (url.includes(separatorChar)) {
    const searchParamsStr = url.substring(url.indexOf(separatorChar) + 1);
    const params = paramsToObject(new URLSearchParams(searchParamsStr));
    return params;
  } else {
    return {};
  }
}

export { getURLParams };
