import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './components/App';
import './styles/main.scss';
import favIcon from './assets/images/favicon.ico';

const favIconLink = document.createElement('link');
favIconLink.rel = 'icon';
favIconLink.type = 'image/ico';
favIconLink.href = favIcon;
document.head.appendChild(favIconLink);
const div = document.createElement('div');
div.className = 'root-container';
div.id = 'app';
document.body.appendChild(div);
const root = ReactDOM.createRoot(div);

root.render(<App />);
