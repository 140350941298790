import React from 'react';

const Done = () => {
  return (
    <div className="done">
      <p>
        Your response has been recorded and results will be shared over email.
        Feel free to close this window.
      </p>
    </div>
  );
};

export default Done;
