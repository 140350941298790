import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';

import sessionAtom from '../../jotai/atoms/session.js';
import MainPanel from '../UI/MainPanel';
import NavPanel from '../UI/NavPanel';

const Quiz = (props) => {
  const [session, setSession] = useAtom(sessionAtom);
  const [currentQuestion, setCurrentQuestion] = useState({
    questionIndex: 0,
    questionId: '',
    text: '',
    optionA: '',
    optionB: '',
    optionC: '',
    optionD: '',
    selected: '',
  });

  useEffect(() => {
    setSession((session) => ({
      ...session,
      questions: props.questions,
      duration: props.duration,
      code: props.code,
    }));
    setCurrentQuestion({ ...props.questions[0], questionIndex: 0 });
  }, []);

  const handleQuestionSelect = (i) => {
    const question = session.questions.find((question, index) => index === i);
    setCurrentQuestion({ ...question, questionIndex: i });
  };

  const handleQuestionPrevious = () => {
    const newIndex = currentQuestion.questionIndex - 1;
    setCurrentQuestion({
      ...session.questions.find((_question, index) => index === newIndex),
      questionIndex: newIndex,
    });
  };

  const handleQuestionNext = () => {
    const newIndex = currentQuestion.questionIndex + 1;
    setCurrentQuestion({
      ...session.questions.find((_question, index) => index === newIndex),
      questionIndex: newIndex,
    });
  };

  const handleOptionSelect = (e) => {
    if (e.target.id === currentQuestion.selected) {
      setSession((session) => ({
        ...session,
        questions: session.questions.map((question) =>
          question.questionId === currentQuestion.questionId
            ? { ...question, selected: '' }
            : question
        ),
      }));
      setCurrentQuestion({ ...currentQuestion, selected: '' });
    } else {
      setSession((session) => ({
        ...session,
        questions: session.questions.map((question) =>
          question.questionId === currentQuestion.questionId
            ? { ...question, selected: e.target.id }
            : question
        ),
      }));
      setCurrentQuestion({ ...currentQuestion, selected: e.target.id });
    }
  };

  const handleSubmitQuiz = () => {
    props.onSubmit(props.code, {
      fullName: session.user.name,
      email: session.user.email,
      attemptedQuestions: session.questions
        .filter((question) => question.selected)
        .map(
          (question) =>
            question.selected && {
              questionId: question.questionId,
              selected: question.selected,
            }
        ),
    });
    setSession((session) => ({
      ...session,
      submitted: true,
      duration: 0,
    }));
  };

  return (
    <>
      <MainPanel
        totalQuestions={session.questions.length}
        question={currentQuestion}
        onOptionSelect={handleOptionSelect}
        onPrevious={handleQuestionPrevious}
        onNext={handleQuestionNext}
      />
      <NavPanel
        currentQuestionId={currentQuestion.questionId}
        questions={session.questions}
        onQuestionSelect={handleQuestionSelect}
        onSubmit={handleSubmitQuiz}
      />
    </>
  );
};

export default Quiz;
