import { atomWithMutation, atomWithQuery } from 'jotai-tanstack-query';

import codeAtom from './code.js';
import quizService from '../../services/quiz.service.js';

const quizAtom = atomWithQuery((get) => {
  const code = get(codeAtom);
  return {
    queryKey: ['quiz', code],
    queryFn: async () => {
      return await quizService.getQuizDetails(code);
    },
    retry: 0,
    enabled: !!code,
  };
});

export { quizAtom };

const submissionAtom = atomWithMutation(() => ({
  mutationKey: ['submissions'],
  mutationFn: async ({ code, data }) => {
    await quizService.submitQuiz(code, data);
  },
}));

export { submissionAtom };
