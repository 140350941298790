import React from 'react';
import { useAtomValue } from 'jotai';

import sessionAtom from '../../jotai/atoms/session.js';
import Header from './Header';
import UserForm from '../User/UserForm';
import Quiz from '../Quiz/Quiz';
import Done from '../Finish/Done';

const Page = ({ code, quiz, onSubmit }) => {
  const session = useAtomValue(sessionAtom);

  return (
    <>
      <header>
        <Header name={quiz.name} duration={quiz.duration} />
      </header>
      <main>
        {session.submitted ? (
          <Done />
        ) : session.user ? (
          <Quiz {...quiz} code={code} onSubmit={onSubmit} />
        ) : (
          <UserForm />
        )}
      </main>
      <footer>&#169; GyaanKreeda 2024</footer>
    </>
  );
};

export default Page;
