import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons';

const MainPanel = ({
  totalQuestions,
  question,
  onOptionSelect,
  onPrevious,
  onNext,
}) => {
  return (
    <div className="app-container">
      <div className="question">
        <div className="question__text">{question.text}</div>
        <div className="question__options">
          <div
            id="A"
            className={`question__option ${question.selected === 'A' && 'question__option--selected'}`}
            onClick={onOptionSelect}
          >
            {question.optionA}
          </div>
          <div
            id="B"
            className={`question__option ${question.selected === 'B' && 'question__option--selected'}`}
            onClick={onOptionSelect}
          >
            {question.optionB}
          </div>
          <div
            id="C"
            className={`question__option ${question.selected === 'C' && 'question__option--selected'}`}
            onClick={onOptionSelect}
          >
            {question.optionC}
          </div>
          <div
            id="D"
            className={`question__option ${question.selected === 'D' && 'question__option--selected'}`}
            onClick={onOptionSelect}
          >
            {question.optionD}
          </div>
        </div>
      </div>
      <div className="question-controls">
        <button
          className="btn--secondary question-controls__action"
          disabled={question.questionIndex === 0}
          onClick={onPrevious}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className="font-awesome__container"
          />
          PREVIOUS
        </button>
        <button
          className="btn--secondary question-controls__action"
          disabled={question.questionIndex === totalQuestions - 1}
          onClick={onNext}
        >
          NEXT
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            className="font-awesome__container"
          />
        </button>
      </div>
    </div>
  );
};

export default MainPanel;
