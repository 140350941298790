import React, { useState } from 'react';

const Code = (props) => {
  const [code, setCode] = useState('');

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  return (
    <div className="code-form-container">
      <div className="code-form">
        <input
          type="text"
          className="code-form__code"
          placeholder="Enter Code"
          defaultValue={code}
          onChange={handleCodeChange}
        ></input>
        <button
          className="btn--primary code-form__submit"
          onClick={() => props.onSubmit(code)}
        >
          SUBMIT
        </button>
      </div>
      <p style={{ color: 'red' }}>{props.message}</p>
    </div>
  );
};

export default Code;
