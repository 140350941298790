import React from 'react';

import Timer from './Timer';

const Header = ({ name, duration }) => {
  return (
    <>
      <div className="header-nav">
        <div className="header__logo-gyaan">GYAAN</div>&nbsp;
        <div className="header__logo-kreeda">KREEDA</div>
      </div>
      <div className="header-rest">
        <div className="header-rest__name">{name}</div>
        <div className="header-rest__timer">
          <Timer duration={duration} />
        </div>
      </div>
    </>
  );
};

export default Header;
