const appConfig = fetch('/config/app-config.json', {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
})
  .then((res) => res.json())
  .then((res) => ({
    prefixUrl: res.apiServerUri,
  }));

const restConfig = appConfig.then((appConfig) => ({
  prefixUrl: appConfig.prefixUrl,
  credentials: 'include',
}));

const configService = { appConfig, restConfig };

export default configService;
