import React from 'react';

import QuestionList from '../Question/QuestionList';

const NavPanel = (props) => {
  return (
    <div className="nav-container">
      <QuestionList {...props} />
      <div className="nav-controls">
        <button
          className="btn--primary nav-controls__submit"
          onClick={props.onSubmit}
        >
          SUBMIT
        </button>
      </div>
    </div>
  );
};

export default NavPanel;
