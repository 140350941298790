import React, { useEffect } from 'react';
import { useAtom } from 'jotai';

import { getURLParams } from '../utils/helper.js';
import codeAtom from '../jotai/atoms/code.js';
import { quizAtom, submissionAtom } from '../jotai/atoms/quiz.js';
import Code from './Code';
import Page from './UI/Page.jsx';
import Error from './Error.jsx';

const App = () => {
  const queryParams = getURLParams();
  const [code, setCode] = useAtom(codeAtom);
  const [
    { data, isLoading: isQuizLoading, isError: isQuizError, error: quizError },
  ] = useAtom(quizAtom);
  const [
    {
      mutate,
      status: submissionStatus,
      isError: isSubmissionError,
      error: submissionError,
    },
  ] = useAtom(submissionAtom);

  const handleCodeSubmit = (code) => {
    setCode(() => code);
  };

  const handleQuizSubmit = (code, data) => {
    mutate({ code, data });
  };

  useEffect(() => {
    if (queryParams.code && queryParams.code != '') {
      setCode(() => queryParams.code);
    }
  }, []);

  if (isQuizLoading || submissionStatus === 'pending')
    return (
      <div className="loader-container">
        <ul>
          <li>
            <div className="loader">
              <div className="child"></div>
            </div>
          </li>
          <li>
            <div className="text"></div>
          </li>
        </ul>
      </div>
    );

  if (isQuizError) {
    return <Code message={quizError.message} onSubmit={handleQuizSubmit} />;
  }

  if (isSubmissionError) {
    return <Error message={submissionError.message} />;
  }

  if (!code) {
    return <Code onSubmit={handleCodeSubmit} />;
  }

  return <Page code={code} quiz={data} onSubmit={handleQuizSubmit} />;
};

export default App;
