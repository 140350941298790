import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const QuestionList = (props) => {
  return (
    <div className="nav-question-list">
      {props.questions.map((question, index) => (
        <div key={index} className="nav-question-list__cell">
          <button
            key={index}
            className={`nav-question${question.selected ? '--answered' : ''}${question.questionId === props.currentQuestionId ? '--selected' : ''}`}
            disabled={question.questionId === props.currentQuestionId}
            value={index}
            onClick={() => props.onQuestionSelect(index)}
          >
            {index + 1}
          </button>
          {question.selected && (
            <FontAwesomeIcon
              className="font-awesome__question"
              icon={faSquareCheck}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default QuestionList;
