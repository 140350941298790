import configService from './config.service.js';

import { RESOURCENOTFOUND } from '../utils/errors.js';

const { prefixUrl } = await configService.appConfig;

async function getQuizDetails(code) {
  const res = await fetch(`${prefixUrl}/quizzes/${code}`, {
    method: 'GET',
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error(
            'Could not get the quiz. Please provide the correct code.'
          );
        }
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }

  const responseBody = await res.json();
  return responseBody;
}

async function submitQuiz(code, params) {
  const res = await fetch(`${prefixUrl}/quizzes/${code}/submissions`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    switch (res.status) {
      case 400:
        const errorBody = await res.json();
        if (errorBody.erroCode === RESOURCENOTFOUND) {
          throw new Error('The quiz is no longer accessible');
        }
      default:
        throw new Error(
          'An unexpected error has occurred. Please contact administrator.'
        );
    }
  }
}

const quizService = {
  getQuizDetails,
  submitQuiz,
};

export default quizService;
